import { type AvailablePrescribingCliniciansParamsDto } from '../scheduling';
import { type PaymentCode } from '../products';
import {
   type CdtGameSwipePhotos,
   type AssessmentRoiAndMedicalClearanceAnswers,
   type CarePlanChoiceEnum,
   type CdtAdsOriginEnum,
   type CdtInsuranceInfoSource,
   type WelkinEncounterTemplateNames,
   type WelkinEventModes,
} from '../welkin';
import { type TimetapError } from '../timetap';
import {
   type StateAbbrValue,
   type StateTitleValue,
} from '@innerwell/us-states';
import {
   type EntheaIntakeAnswers,
   type SignupScreeningAnswers,
} from '../formsort';
import { type NonEligibleError } from '../insurance';
import { type NmiActionType } from '../nmi';
import { type CdtBreathingExercises } from '../welkin/cdts/cdt-game_breathing_exercises';

export enum CustomerIOTemplates {
   AppointmentConfirmationDefault = 2,
   AppointmentConfirmationClinician = 4,

   MedicalConsult = 8,
   TherapistIntake = 9,
   PrepCall = 10,
   KAP = 11,
   IntegrationSession = 12,
   MedicalCheckIn = 13,
   FinalMedicalCheckIn = 14,
   PsychotherapySession = 25,

   // Sent to patient when they no show
   PatientMissedAppointment = 17,
   // Sent to patient when appointment is cancelled but isn't because patient has missed it (template above)
   CancelledAppointmentPatientTemplate = 24,
   // Sent to clinicians when appointment is cancelled
   CancelledAppointmentClinicianTemplate = 22,

   PaymentReceived = 19,
   PreAuthReceived = 39,
   // Use for test payment received (update in code)
   TestPaymentReceived = 28,
   // Used for cancelled payments
   RefundReceipt = 27,

   ALaCarteAppointment = 26,

   CoPayReceipt = 35,
}

export enum CustomerIOEvents {
   TriageScreenerStarted = 'triage_started',
   TriageScreenerFinished = 'triage_finished',
   SignupScreenerStarted = 'signup_screener_started',
   SignupScreenerRejected = 'signup_screener_rejected',
   SignupScreenerStep = 'signup_screener_step',
   SignupScreenerComplete = 'signup_screener_complete',

   // Initial screening + Purchase events
   InitialScreeningCompletedClickContinue = 'initial_screening_completed-click_continue',
   InitialScreeningCompletedBrowserWindowClosed = 'initial_screening_completed-browser_window_closed',
   InsuranceClickYes = 'insurance-click_yes',
   InsuranceClickNo = 'insurance-click_no',
   PurchaseSelectProduct = 'purchase-select_product',
   PurchaseClickAddAndContinue = 'purchase-click_addandcontinue',
   PurchaseClickBack = 'purchase-click_back',
   PurchaseUpsellClickAddTherapy = 'purchase_upsell-click_addtherapy',
   PurchaseUpsellClickCheckout = 'purchase_upsell-click_checkout',
   PurchaseUpsellClickSkip = 'purchase_upsell-click_skip',
   PurchaseUpsellClickBack = 'purchase_upsell-click_back',
   PurchaseUpsellClickRemove = 'purchase_upsell-click_remove',
   PostPurchaseUpsellClickAddTherapy = 'post_purchase_upsell-click_addtherapy',
   PostPurchaseUpsellClickSkip = 'post_purchase_upsell-click_skip',
   CheckoutClickPaymentMethod = 'checkout-click_paymentmethod',
   CheckoutDiscountCodeInput = 'checkout-discount_code_input',
   CheckoutDiscountCodeRemove = 'checkout-discount_code_remove',
   CheckoutAddressValidationError = 'checkout-address_validation_error',
   CheckoutClickPay = 'checkout-click_pay',
   CheckoutClickPurchaseButton = 'checkout-click_pay_now',
   PatientStartedPurchaseFlow = 'patient_started_purchase_flow',

   EntheaDetailsReceived = 'enthea_details_received',

   SitterUpdated = 'sitter_updated',

   SignupAccountCreationPending = 'signup_accountCreation_pending',
   SignupAccountCreationComplete = 'signup_accountCreation_complete',
   // Triggered for auth and sale
   SignupPaymentComplete = 'signup_payment_completed',
   // Triggered for capture action
   SignupPaymentCapture = 'signup_payment_capture',

   BackendSignupRequestReceived = 'backend_signup_payment_request_received',
   BackendSignupInitialScreeningStatus = 'backend_signup_initial_screening_status',
   BackendSignupCognitoUserCreated = 'backend_signup_cognito_user_created',
   BackendSignupCognitoUserConfirmed = 'backend_signup_cognito_user_confirmed',
   BackendSignupPatientCreated = 'backend_signup_patient_created',
   BackendSignupDataRelationsSuccess = 'backend_signup_data_relations_success',
   BackendSignupError = 'backend_signup_error',
   BackendSignupProgramPhaseChanged = 'backend_signup_program_phase_changed',
   BackendSignupInsuranceInfoSaved = 'backend_signup_insurance_info_saved',
   BackendSignupShouldCheckInsuranceEligibility = 'backend_signup_should_check_insurance_eligibility',

   OnboardingStarted = 'onboarding_started',
   OnboardingRejected = 'onboarding_rejected',
   OnboardingComplete = 'onboarding_complete',
   OnboardingConsentSigned = 'onboarding_consentSigned',

   UploadIdStarted = 'upload_id_started',
   UploadIdFinished = 'upload_id_finished',

   SendInvoice = 'send_invoice',

   AppointmentScheduled = 'appointment_scheduled',
   AppointmentCanceled = 'appointment_canceled',

   // When appointment update hook fails
   AppointmentUpdateError = 'appointment_update_error',
   // When clinician fetches a new meeting id to get a new host start_url param
   AppointmentZoomStartUrlRegenerated = 'appointment_zoom_start_url_regenerated',
   AppointmentZoomJoinUrlRequested = 'appointment_zoom_join_url_requested',

   MedicalConsultAccepted = 'medical_consult_accepted',
   MedicalConsultRejected = 'medical_consult_rejected',

   DailyMoodTracked = 'daily_mood_tracked',
   WeeklyPauseTracked = 'weekly_pause_tracked',

   DosingSessionStarted = 'dosing_session_started',

   ShipmentCreated = 'shipment_created',

   SignupPaymentStarted = 'signup_payment_started',
   // Triggered for payments that are not signup payments
   PaymentSuccessful = 'payment_successful',
   AddOnPaymentSuccessful = 'add_on_payment_successful',
   MedicalAddOnPaymentSuccessful = 'medical_add_on_payment_successful',
   FollowOnPaymentSuccessful = 'follow_on_payment_successful',

   // These are triggered on NMI webhook
   PaymentFailed = 'payment_failed',
   SignupPaymentFailed = 'signup_payment_failed',
   AddOnPaymentFailed = 'add_on_payment_failed',
   MedicalAddOnPaymentFailed = 'medical_add_on_payment_failed',
   FollowOnPaymentFailed = 'follow_on_payment_failed',

   // On encounter disposition update to Patient no show
   PatientMissedAppointment = 'patient_missed_appointment',
   MissedAppointmentFeePaid = 'missed_appointment_paid',
   CancelAppointmentConfirmed = 'cancel_appointment_confirmed',
   CancelAppointmentFeePaid = 'cancel_appointment_paid',

   PaymentSwitchProgramSuccessful = 'payment_switch_program_successful',
   PaymentSwitchProgramFailed = 'payment_switch_program_failed',

   NoAvailableClinicians = 'no_available_clinicians',

   ChatMessageToClinician = 'message_sent_to_clinician',
   ChatMessageToPatient = 'message_sent_to_patient',
   ChatError = 'chat_message_failed',

   RoiRequested = 'roi_requested',

   SignIn = 'sign_in',
   SignOut = 'sign_out',

   DuplicateAccount = 'possible_duplicate_account',

   EarlyResignation = 'early_resignation',

   TimetapSchedulingError = 'timetap_scheduling_error',

   NmiWebhookReceived = 'nmi_webhook_received',

   InternalError = 'internal_error',

   ContinuedCarePlanRecommendation = 'continued-care-plan-recommendation',
   ContinuedCarePatientNotEligible = 'patient-not-eligible-to-continue-care',
   ContinuedCarePatientDoesNotWantToContinue = 'patient-does-not-want-to-continue',
   ContinuedCarePatientWantsToContinue = 'patient-wants-to-continue-care',
   ContinuedCarePatientThinkingAboutContinuing = 'patient-is-thinking-about-continuing-care',

   NotifyMeWhenStateAvailable = 'notify_me_when_state_available',

   RoiNeeded = 'roi_needed',
   MedicalRecordsReceived = 'medical_records_received',
   MedicalClearanceReceived = 'clearance_received',
   RoiReceived = 'roi_received',
   DischargeLetterAndTreatmentSummaryReceived = 'discharge_letter_received',

   AudioPlayerError = 'audio_player_error',

   SafetyPlanRequested = 'safety_plan_requested',
   SafetyPlanReceived = 'safety_plan_received',

   PrescriptionError = 'prescription_error',
   PrescriptionWarning = 'prescription_warning',

   PatientSurveyCompleted = 'patient_survey_completed',
   EligibilityCheck = 'eligibility_check',
   InNetworkCheck = 'in_network_check',

   LabsDocumentUploaded = 'labs_document_uploaded',

   KetaminePrescriptionSubmitted = 'ketamine_prescription_submitted',

   // data driven care
   PatientImprovementPhqGad = 'patient_improvement_phq_gad',
   PatientDischarged = 'patient_discharged ',
   PatientCliniciansInputsTracking = 'patient_clinicians_inputs_tracking',
   PatientCliniciansInputsDosage = 'patient_clinicians_inputs_dosage',
   PatientFollowUpThreePhase = 'follow_up_3_phase',

   // Games
   SwipeExerciseFinished = 'swipe_exercise_finished',
   BreathingExerciseFinished = 'breathing_exercise_finished',
}

export enum ShipmentType {
   First = 'first',
   Refill = 'refill',
}

export interface CustomerIOSitter {
   name: string;
   email: string;
   phone: string;
}

export interface PaymentEventBaseFields {
   transaction_id: string;
   transaction_amount: string;
   transaction_date: string;
   frequency: PaymentCode; // legacy
   code: PaymentCode;
   currency: string;
   payment_method: string;
   chosen_plan: string;
   free_text?: string;
   free_text_2?: string;
   product_names?: string;
   error: unknown;
   // Null when we create a fake failed transaction event without transaction
   action_type: NmiActionType | null;
}

export interface SuccessfulPaymentEventFields extends PaymentEventBaseFields {
   plan_id: string;
   product_title: string;
   product_duration: string;
   total_price: string;
}

export interface AppointmentEventData {
   appointment_id: string;
   appointment_event_mode: WelkinEventModes;
   appointment_type: WelkinEncounterTemplateNames;
   appointment_type_name: string;
   appointment_clinician_name: string;
   appointment_start_time_unix: number;
   appointment_time: string;
   appointment_date: string;
   appointment_date_time: string;
   appointment_end_time: string;
   appointment_end_date: string;
   appointment_url: string;
   appointment_reschedule_url: string;
   zoom_join_url: string;
   // Not required since there might be appointments for which sitter is not applicable
   sitter?: CustomerIOSitter;
   appointment_location_directions: string;
   appointment_location_directions_extended: string;
}

export interface RoiDocumentEventData {
   body: unknown;
}

export interface AudioPlayerErrorEventData {
   osName: string;
   osVersion: string;
   browserName: string;
   browserVersion: string;
   mobileVendor: string;
   mobileModel: string;
   engineName: string;
   engineVersion: string;
   events: unknown[];

   error: {
      code: number;
      message: string;
   } | null; // null for iOS errors
}

export type AppointmentUpdateErrorData = {
   patientId: string;
   encounterId: string;
   errorType: string;
   errorMessage: string;
   data: unknown;
};

export type SignupScreenerStepData = {
   answers: Partial<SignupScreeningAnswers>;
};

export type SignupScreenerRejectedData = {
   email: string | undefined;
   reason: string;
   // full state name
   state?: string;
};

export type InNetworkCheckData = {
   isCovered: boolean;
   // state abbr
   state: string;
};

export type TriageScreenerFinishedData = {
   what_brings_you_here: number[];
   product_options: string;
};

export type SignupScreenerCompleteData = {
   what_brings_you_here: number[];
   product_options: string;
   answers: SignupScreeningAnswers;
};

export type TriageScreenerStartedData = {
   email: string;
};

export type SignupScreenerStartedData = {
   email: string;
   url: string;
};

export type DosingSessionStartedData = {
   time_started: string;
   time_ended: string | null;
   counter: number;
   platform_type: CdtAdsOriginEnum;
};

export type GetCustomerAttributesResponse = {
   customer: {
      id: string;
      identifiers: {
         cio_id: string;
         email: string;
      };
      attributes: Record<string, unknown>;
      timestamps: unknown;
   };
};

export interface InitialScreeningCompletedBrowserWindowClosedEventData {
   exit_url: string;
}

export interface PurchaseSelectProductEventData {
   sku: string;
}

export interface PurchaseUpsellClickAddTherapyEventData {
   sku: string;
}

export interface CheckoutClickPaymentMethodEventData {
   method: string;
}

export interface CheckoutDiscountCodeInputEventData {
   discount_code: string;
   success: boolean;
   error: {
      message: string;
   } | null;
}

export interface CheckoutAddressValidationErrorEventData {
   error: string;
}

export interface CheckoutClickPayEventData {
   order_id: string | null;
   success: boolean;
   error: {
      message: string;
   } | null;
}

export interface CheckoutClickPurchaseButtonEventData {
   headline: string;
   description: string;
   selected_payment_provider: string;
   selected_payment_frequency: PaymentCode;
}

export interface PatientStartedPurchaseFlowEventData {
   url: string;
}

export interface CancelAppointmentConfirmedEventData {
   encounterId: string;
}

export type CustomerIOEventMap = {
   [CustomerIOEvents.AppointmentScheduled]: AppointmentEventData;
   [CustomerIOEvents.AppointmentCanceled]: AppointmentEventData;
   [CustomerIOEvents.AppointmentUpdateError]: AppointmentUpdateErrorData;
   [CustomerIOEvents.PatientMissedAppointment]: AppointmentEventData;
   [CustomerIOEvents.SignupScreenerStep]: SignupScreenerStepData;
   [CustomerIOEvents.SignupScreenerComplete]: SignupScreenerCompleteData;
   [CustomerIOEvents.TriageScreenerFinished]: TriageScreenerFinishedData;
   [CustomerIOEvents.MedicalConsultAccepted]: {
      plan_confirmed: CarePlanChoiceEnum;
      dosing_frequency: string | null;
   };
   [CustomerIOEvents.TriageScreenerStarted]: TriageScreenerStartedData;
   [CustomerIOEvents.SignupScreenerStarted]: SignupScreenerStartedData;
   [CustomerIOEvents.DailyMoodTracked]: {
      mood_score: number;
   };
   [CustomerIOEvents.ShipmentCreated]: {
      tracking_number: string;
      shipment_type: ShipmentType;
   };
   [CustomerIOEvents.DosingSessionStarted]: DosingSessionStartedData;
   [CustomerIOEvents.SignupScreenerRejected]: SignupScreenerRejectedData;
   [CustomerIOEvents.SitterUpdated]: {
      sitter?: CustomerIOSitter;
   };
   [CustomerIOEvents.SendInvoice]: {
      selected_plan: string;
   };
   [CustomerIOEvents.SignupPaymentStarted]: {
      plan_id: string;
      plan_name: string;
      total_price: string;
   };
   [CustomerIOEvents.SignupPaymentComplete]: SuccessfulPaymentEventFields;
   [CustomerIOEvents.SignupPaymentCapture]: SuccessfulPaymentEventFields;
   [CustomerIOEvents.AddOnPaymentSuccessful]: SuccessfulPaymentEventFields;
   [CustomerIOEvents.MedicalAddOnPaymentSuccessful]: SuccessfulPaymentEventFields;
   [CustomerIOEvents.FollowOnPaymentSuccessful]: SuccessfulPaymentEventFields;
   [CustomerIOEvents.MissedAppointmentFeePaid]: SuccessfulPaymentEventFields;
   [CustomerIOEvents.CancelAppointmentFeePaid]: SuccessfulPaymentEventFields;
   [CustomerIOEvents.PaymentSwitchProgramSuccessful]: SuccessfulPaymentEventFields;
   [CustomerIOEvents.BackendSignupRequestReceived]: {
      email: string;
      patientId: string | null;
      formsortUUID: string | null;
      databaseInsuranceInfoId: string | null;
   };
   [CustomerIOEvents.BackendSignupInitialScreeningStatus]: {
      formsortUUID: string | null;
      formsortDatabaseId: string | null;
   };
   [CustomerIOEvents.BackendSignupCognitoUserCreated]: {
      cognitoSub: string;
      createdAsAdmin: boolean;
   };
   [CustomerIOEvents.BackendSignupPatientCreated]: {
      patientId: string;
   };
   [CustomerIOEvents.BackendSignupDataRelationsSuccess]: {
      cognitoSub: string;
      formsortUUID: string;
   };
   [CustomerIOEvents.BackendSignupError]: {
      message: string;
      error: unknown;
   };
   [CustomerIOEvents.BackendSignupProgramPhaseChanged]: {
      success: boolean;
      error: unknown;
      errorMessage: string | null;
   };
   [CustomerIOEvents.BackendSignupShouldCheckInsuranceEligibility]: {
      purchasedCategoryIds: string[];
      shouldCheck: boolean;
      insuranceCategoryId: number;
   };
   [CustomerIOEvents.PaymentSuccessful]: SuccessfulPaymentEventFields;
   [CustomerIOEvents.SignupPaymentFailed]: PaymentEventBaseFields;
   [CustomerIOEvents.PaymentFailed]: PaymentEventBaseFields;
   [CustomerIOEvents.AddOnPaymentFailed]: PaymentEventBaseFields;
   [CustomerIOEvents.MedicalAddOnPaymentFailed]: PaymentEventBaseFields;
   [CustomerIOEvents.FollowOnPaymentFailed]: PaymentEventBaseFields;
   [CustomerIOEvents.PaymentSwitchProgramFailed]: PaymentEventBaseFields;
   [CustomerIOEvents.NoAvailableClinicians]: AvailablePrescribingCliniciansParamsDto;
   [CustomerIOEvents.ChatMessageToPatient]: {
      patient_url?: string;
   };
   [CustomerIOEvents.ChatMessageToClinician]: {
      patient_id: string;
      message_id: number | undefined;
      clinician_name: string;
      clinician_email: string;
   };
   [CustomerIOEvents.ChatError]: {
      direction: string; // Welkin -> Sendbird or Sendbird -> Welkin
      error?: unknown;
   };
   [CustomerIOEvents.DuplicateAccount]: {
      existing_welkin_id: string;
      existing_welkin_name: string;
      new_welkin_id: string;
      new_welkin_name: string;
   };
   [CustomerIOEvents.InternalError]: {
      errorType: string | undefined;
      message: string | undefined;
      data: unknown | undefined;
      stack: string | null | undefined;
   };

   [CustomerIOEvents.EarlyResignation]: {
      reason: string;
   };

   [CustomerIOEvents.ContinuedCarePlanRecommendation]: {
      value: string | undefined;
   };

   [CustomerIOEvents.TimetapSchedulingError]:
      | {
           timetapError: TimetapError;
           // send all params from the appointments.service
           params: unknown;
           bounds: {
              start: string | null;
              end: string | null;
           };
        }
      | {
           error: unknown;
           params: unknown;
        };

   [CustomerIOEvents.NmiWebhookReceived]: {
      body: unknown;
      bodyVerified: boolean;
      nmiSig: string;
      nmiSenderTag: string;
      appSenderTag: string;
      ignoreIncorrectSignature: string;
      shouldEmit: boolean;
   };

   [CustomerIOEvents.NotifyMeWhenStateAvailable]: {
      stateAbbr: StateAbbrValue;
      stateTitle: StateTitleValue | 'N/A';
      notify: boolean;
   };

   [CustomerIOEvents.RoiNeeded]: {
      answers: AssessmentRoiAndMedicalClearanceAnswers;
   };

   [CustomerIOEvents.MedicalRecordsReceived]: RoiDocumentEventData;
   [CustomerIOEvents.MedicalClearanceReceived]: RoiDocumentEventData;
   [CustomerIOEvents.RoiReceived]: RoiDocumentEventData;
   [CustomerIOEvents.DischargeLetterAndTreatmentSummaryReceived]: RoiDocumentEventData;

   [CustomerIOEvents.AudioPlayerError]: AudioPlayerErrorEventData;

   [CustomerIOEvents.PrescriptionError]: {
      error: string;
      prescriptionEntityId: string; // DB id
      clinicianWelkinId?: string;
      clinicianName?: string;
   };

   [CustomerIOEvents.PrescriptionWarning]: {
      description: string;
      patientId: string;
      cdtId: string;
      prescriptionEntityId: string;
      lastWrittenDate: string;
   };

   [CustomerIOEvents.PatientSurveyCompleted]: {
      answers: unknown;
   };

   [CustomerIOEvents.LabsDocumentUploaded]: {
      sourceName: string;
      instanceName: string;
   };

   [CustomerIOEvents.EligibilityCheck]: {
      eligible: boolean | null; // null when we don't have enough data to determine eligibility
      eligibilityError?: NonEligibleError | null; // one of our NonEligible codes or null
      databaseEntityId?: string;

      // ClaimMD error details
      claimMdErrors?: unknown;
      serviceError?: boolean;

      // Unknown error details
      unknownError?: unknown;

      source: CdtInsuranceInfoSource;
   };

   [CustomerIOEvents.InNetworkCheck]: InNetworkCheckData;
   [CustomerIOEvents.PurchaseSelectProduct]: PurchaseSelectProductEventData;
   [CustomerIOEvents.InitialScreeningCompletedBrowserWindowClosed]: InitialScreeningCompletedBrowserWindowClosedEventData;
   [CustomerIOEvents.PurchaseUpsellClickAddTherapy]: PurchaseUpsellClickAddTherapyEventData;
   [CustomerIOEvents.CheckoutClickPaymentMethod]: CheckoutClickPaymentMethodEventData;
   [CustomerIOEvents.CheckoutDiscountCodeInput]: CheckoutDiscountCodeInputEventData;
   [CustomerIOEvents.CheckoutAddressValidationError]: CheckoutAddressValidationErrorEventData;
   [CustomerIOEvents.CheckoutClickPay]: CheckoutClickPayEventData;
   [CustomerIOEvents.CheckoutClickPurchaseButton]: CheckoutClickPurchaseButtonEventData;
   [CustomerIOEvents.PatientStartedPurchaseFlow]: PatientStartedPurchaseFlowEventData;
   [CustomerIOEvents.EntheaDetailsReceived]: {
      answers: EntheaIntakeAnswers;
   };

   [CustomerIOEvents.KetaminePrescriptionSubmitted]: {
      status: string;
      medication: string;
      clinicianName: string;
      dose: string;
      doseUnit: string;
      quantityUnit: string;
      quantity: string;
      fillDate: string;
      startDate: string | null;
      stopDate: string | null;
      pharmacyName: string;
   };

   // data driven care
   [CustomerIOEvents.PatientImprovementPhqGad]: {
      type: string; // which report
      scores: number[];
      dates: string[];
      totalImprovement: number;
   };
   [CustomerIOEvents.PatientDischarged]: {
      dischargeDate: string;
   };
   [CustomerIOEvents.PatientCliniciansInputsDosage]: {
      treatmentTrackingConclusion: string;
      treatmentNeedToTrack: boolean;
      patientTrackedDosage: number;
      patientShouldTrack: number;
   };
   [CustomerIOEvents.PatientCliniciansInputsTracking]: {
      phq9Conclusion: string;
      pcl5Conclusion: string;
      gad7Conclusion: string;
      phq9NeedToTrack: boolean;
      gad7NeedToTrack: boolean;
      pcl5NeedToTrack: boolean;
   };

   [CustomerIOEvents.AppointmentZoomStartUrlRegenerated]: {
      zoomMeetingId: number;
      start: string;
      encounterId: string;
      url: string;
   };
   [CustomerIOEvents.AppointmentZoomJoinUrlRequested]: {
      zoomMeetingId: number;
      encounterId: string;
      joinUrl: string;
   };
   [CustomerIOEvents.CancelAppointmentConfirmed]: CancelAppointmentConfirmedEventData;
   [CustomerIOEvents.WeeklyPauseTracked]: Record<
      string,
      Record<string, unknown>
   >;
   [CustomerIOEvents.PatientFollowUpThreePhase]: {
      encounter_count_finalized: number;
      encounter_count_in_progress: number;
   };

   [CustomerIOEvents.BreathingExerciseFinished]: Omit<
      CdtBreathingExercises,
      'cdtf-exercise_name'
   > & {
      'cdtf-exercise_name': string;
   };
   [CustomerIOEvents.SwipeExerciseFinished]: CdtGameSwipePhotos;
};
